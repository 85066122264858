/** @jsx jsx */
import {Container, jsx} from 'theme-ui'
import Super from "@lekoarts/gatsby-theme-cara/src/components/layout"
import Logo from "./logo";

const Layout = ({children}) => (
    <Super>
      <Logo/>
      <Container>
        {children}
      </Container>
    </Super>
)

export default Layout
