import React from "react"
import Helmet from "react-helmet"
import useSiteMetadata from "@lekoarts/gatsby-theme-cara/src/hooks/use-site-metadata"

type Props = {
  children?: React.ReactNode
}

const SEO = ({ children }: Props) => {
  const site = useSiteMetadata()

  const {
    siteTitle,
    siteTitleAlt,
    siteUrl,
    siteDescription,
    siteLanguage,
  } = site

  return (
    <Helmet title={siteTitle} defaultTitle={siteTitleAlt} titleTemplate={`%s | ${siteTitle}`}>
      <html lang={siteLanguage} />
      <meta name="description" content={siteDescription} />
      <meta property="og:title" content={siteTitle} />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:description" content={siteDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:image:alt" content={siteDescription} />
      {children}
    </Helmet>
  )
}

export default SEO
