/** @jsx jsx */
import {jsx} from 'theme-ui'

const Logo = () => (
    <div css={{height: '120px'}}>
      <a href="/" css={{position: "absolute", zIndex: 5}}>
        <img src="/logo-with-text.svg" alt="Pristine Bytes Logo" css={{margin: "30px", width: "400px"}}/>
      </a>
    </div>
)

export default Logo
